import { useRef } from 'react';
import { StickyAd as AdStickySidebar } from '../Ads/units/AdStickySidebar';
const AD_SIDEBAR_START = 4;

export const EverythingElseSidebar = ({ index }) => {
  const localSidebarRef = useRef(null);
  let rightColumnClasses = 'homepage-rightColumn';

  if (index === 0) {
    rightColumnClasses += ' xs-mt6';
  }

  const sidebarAdNum = AD_SIDEBAR_START + index;
  let sidebarAdPos = `promo${sidebarAdNum}`;
  if (sidebarAdNum > 10) {
    sidebarAdPos = 'promo-infinite';
  }

  return (
    <div className={rightColumnClasses} ref={localSidebarRef}>
      <AdStickySidebar adPos={sidebarAdPos} sidebarEl={localSidebarRef} />
    </div>
  );
};

export default EverythingElseSidebar;
