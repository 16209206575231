/* eslint-disable react/display-name */
import React from 'react';
import { useViewImpression } from '../../hooks';
import LinkWithTracking from '../LinkWithTracking';
import { RightCaret as SeeMoreIcon } from '../../icons/RightCaret';
import styles from './seeMoreLink.module.scss';

export const SeeMoreLink = ({
  children,
  className,
  commonTrackingData,
  href,
  isExternal,
}) => {
  // impression tracking
  const { setObservable } = useViewImpression({
    trackingData: commonTrackingData
  });
  return (
    <div
      ref={oEl => setObservable(oEl)}
      className={className}
    >
      <LinkWithTracking
        href={href}
        commonTrackingData={commonTrackingData}
        isExternal={isExternal}
      >
      <span>{children}</span>
      <SeeMoreIcon
        aria-hidden="true"
        className={`svg-6 svg-blue ${styles.caretIcon}`}
      />
    </LinkWithTracking>
  </div>
  );
};
export default SeeMoreLink;