/* eslint-disable camelcase */
import React from 'react';
import {
  useMediaQuery,
  useViewImpression,
} from '../../hooks';
import { SubunitTrackingProvider } from '../../contexts';
import { getThumbnail, addImageParams } from '../../utils/data';
import LinkWithTracking from '../LinkWithTracking';
import Feed from '../Feed';
import styles from './splashPackage.module.scss';

// TODO : needs the actual package instead of a singular post

const BIG = 'dblbig';
const WIDE = 'dblwide';

export const SplashPackage = ({ splash, data }) => {
  const screenSize = useMediaQuery();

  const splashTrackingData = {
    item_type: 'splash',
    item_name: splash.id,
    position_in_unit: 0,
    target_content_type: 'buzz',
    target_content_id: splash.id,
  };

  const pinnedTrackingData = {
    position_in_unit: 1,
    subunit_name: 'pinned_posts',
    subunit_type: 'package'
  };

  const { setObservable } = useViewImpression({ trackingData: splashTrackingData });

  const dblBigImage = getThumbnail(splash.thumbnails[0].sizes, BIG);
  const dblWideImage = getThumbnail(splash.thumbnails[0].sizes, WIDE);
  const customImage = splash?.custom_splash_thumbnail?.url;

  // if the custom image matches the dblBig image, there is no custom image
  let image =
    !customImage || customImage === dblBigImage ? dblWideImage : customImage;
  image = image.replace(`_${BIG}`, `_${WIDE}`); // catch weird crops from homebuilder

  const imageAltText =
    customImage === dblBigImage // no custom image
      ? splash.thumbnails[0].sizes[0]?.alt_text
      : splash.custom_splash_thumbnail?.alt_text;

  return (
    <div className="md-mt2" ref={obvEl => setObservable(obvEl)}>
      <LinkWithTracking
        href={`${splash.source_uri}?origin=spl`}
        className="xs-flex xs-flex-column text-gray xs-mb4"
        commonTrackingData={splashTrackingData}
      >
        {image && (
          <img
            className={styles.splashImage}
            fetchpriority="high" // eslint-disable-line 
            src={addImageParams(image, { fill: '700:250;center,top' })}
            alt={imageAltText}
            width="625"
            height="220"
          />
        )}
        <h1 className={styles.heading}>{splash.name}</h1>
      </LinkWithTracking>
      <SubunitTrackingProvider value={pinnedTrackingData}>
        {/*
          * Due to the server render always being desktop, the first render
          *  will consider screenSize via a media query as desktop.
          *  The column setting provides different styling, and as such
          *  there is a layout shift on mobile after client hydration if
          *  trying to dynamically set column value.
          */}
        <div className="xs-hide md-block">
          <Feed
            items={data.items.slice(0, 3)}
            columns={3}
            typeInfo={data.typeInfo}
            hasRightText={false}
            hasSmallTitle
          />
        </div>
        <div className="md-hide">
          <Feed
            items={data.items.slice(0, 3)}
            columns={1}
            typeInfo={data.typeInfo}
            hasRightText={true}
            hasSmallTitle
          />
        </div>
      </SubunitTrackingProvider>
    </div>
  );
};

export default SplashPackage;
