/* eslint-disable camelcase */
import { useState, useCallback, useContext, useEffect } from 'react';
import Button from '@buzzfeed/react-components/lib/components/Button';
import { AdsContext } from '@buzzfeed/adlib/dist/module/bindings/react/contexts';
import { SubunitTrackingProvider } from '../../contexts';
// components
import Feed from '../Feed';
import { StoryFeedManager } from '../Ads/managers/StoryFeedManager';
import { AdSection, AdSectionWide } from '../Ads/units/AdSection';
import { EverythingElseSidebar } from './Sidebar';

// hooks & utils
import { useLazyLoad, useMediaQuery, useTrackingContext } from '../../hooks';
import { normalizeFeedData } from '../../utils/data';
import styles from './everythingElse.module.scss';
// constants
import { LOCAL_API, ROUTED_API } from '../../constants';
import { MORE } from '../../constants/feeds';
import adsConfig from '../../constants/ads-config';

const SUBUNIT_NAME = 'more_stories_last';
const FETCH_PAGE_SIZE = 112;
const GROUP_PAGE_SIZE = 6;
// eslint-disable-next-line no-undef
const API_URL = process.env.NODE_ENV !== 'development' ? ROUTED_API : LOCAL_API;
// these are based on what was placed in pages/index.jsx
const AD_STORY_WIDE = 7;
const AD_STORY_MWEB = 10;

export const EverythingElse = props => {
  // storyN ad units - desktop 6, mweb 11
  const { isMobile } = useMediaQuery();
  const adsContext = useContext(AdsContext);
  const [adManager, setAdManager] = useState(null);

  const trackingCommon = {
    position_in_unit: props.positionInUnit,
    subunit_name: SUBUNIT_NAME,
    subunit_type: 'package',
  };

  useEffect(() => {
    if (adsContext.status !== 'loaded') {
      return () => {};
    }
    let unitConfig = [];
    for (let i = isMobile ? AD_STORY_MWEB : AD_STORY_WIDE; i < 41; i++) {
      unitConfig.push(adsConfig[`story${i}`]);
    }

    const manager = new StoryFeedManager({
      config: {
        units: unitConfig,
        pattern: [false, true],
      },
    });

    manager.init().then(
      () => {
        setAdManager(manager);
      },
      // if there are no ads to show (on partner posts on desktop),
      // `init` will throw a `Disabled` error
      () => {}
    );
    return () => {
      setAdManager(null);
      manager.destroy();
    };
  }, [adsContext, isMobile]);

  return (
    <SubunitTrackingProvider value={trackingCommon}>
      <EverythingElsePresenter
        {...props}
        adManager={adManager}
        isMobile={isMobile}
      />
    </SubunitTrackingProvider>
  );
};

// we can do an initial fetch on the front-end
// this module is at the end, so it'll have time to load
let pageCounter = 0;
export const EverythingElsePresenter = ({
  adManager,
  data,
  positionInUnit,
  isMobile,
  feed = 'buzzfeed-home',
}) => {
  const { trackContentAction } = useTrackingContext();
  const { canLoad, setObservable } = useLazyLoad({
    preloadDistance: 1,
    onlyOnce: false,
  });
  const [displayPageQueue, setDisplayPageQueue] = useState([]);
  const [displayPages, setDisplayPages] = useState([]);
  const [fetchFinished, setFetchFinished] = useState(false);
  const [fetchPageNum, setFetchPageNum] = useState(1);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isFinished, setIsFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [itemQueue, setItemQueue] = useState([]);
  // group initial pages and just render them
  const { typeInfo } = data;
  const ssrItems = data.items;

  const getTotalItems = useCallback(() => {
    const totalItems = displayPages.reduce(
      (total, curr) => (curr.type === 'ad' ? total : total + curr.items.length),
      0
    );
    return totalItems;
  }, [displayPages]);

  // load more button stuff
  function trackLoadMoreButton() {
    const buttonPosition = getTotalItems() + 1;
    // update total items for Load More button tracking
    trackContentAction({
      action_type: 'show',
      action_value: 'show',
      item_name: 'load_more',
      item_type: 'button',
      position_in_subunit: buttonPosition,
    });
  }

  // grouping pages
  function groupItems(_items, sliceLength = GROUP_PAGE_SIZE) {
    let groups = [];
    let leftovers = [];
    for (let i = 0; i < _items.length / sliceLength; i++) {
      let g = _items.slice(i * sliceLength, i * sliceLength + sliceLength);
      if (g.length < sliceLength) {
        leftovers = [...g];
      } else {
        groups.push({
          key: `group-${pageCounter++}`,
          counter: pageCounter,
          items: _items.slice(i * sliceLength, i * sliceLength + sliceLength),
        });
      }
    }
    return { groups, leftovers };
  }
  // load items from the queue into the displayPageQueue
  const loadItemsFromQueue = useCallback(() => {
    if (fetchFinished && (isFinished || itemQueue?.length <= 0)) {
      return;
    }
    setIsLoading(true);
    const nextPages = groupItems(
      itemQueue,
      itemQueue.length < GROUP_PAGE_SIZE ? itemQueue.length : GROUP_PAGE_SIZE
    );

    setDisplayPageQueue([...displayPageQueue, ...nextPages.groups]);
    if (nextPages.leftovers.length <= 0) {
      setIsFinished(true);
    } else {
      setItemQueue([...nextPages.leftovers]);
    }
    setIsLoading(false);
  }, [displayPageQueue, fetchFinished, isFinished, itemQueue]);

  const handlePageButton = ev => {
    if (!isFinished) {
      ev.target.blur(); // unfocus the button...?
      trackLoadMoreButton();
      loadItemsFromQueue();
    }
  };

  // ssr load
  if (initialLoad) {
    const initLoadGroups = groupItems(ssrItems);
    setDisplayPageQueue([...initLoadGroups.groups]);
    setItemQueue([...initLoadGroups.leftovers]);
    setInitialLoad(false);
  }

  // ad manager splicing
  useEffect(() => {
    let nextDisplayedItems = [...displayPageQueue];
    if (adManager) {
      let idx = 0;
      do {
        const ad = adManager.getAdForPlacement(idx);
        const item = nextDisplayedItems[idx];
        const isAdItem = item && item.type === 'ad';

        if (adManager.isDone(ad)) {
          // there will be no more ads
          break;
        }
        if (ad) {
          ad.type = 'ad';
          // insert an ad at index, or replace the current item if it's already an ad (inserted earlier)
          nextDisplayedItems.splice(idx, isAdItem ? 1 : 0, ad);
          idx++;
        }
        idx++;
      } while (idx < nextDisplayedItems.length);

      // reset the manager so it starts generating ads from the beginning when the effect runs next time
      adManager.reset();
    }
    setDisplayPages([...nextDisplayedItems]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adManager, displayPageQueue]);

  // client-side fetch of pages
  useEffect(() => {
    if (fetchFinished || isFinished || initialLoad || fetchPageNum < 2) {
      return;
    }
    // change the apiURL if it's NAMESPACE to use local API
    const apiUrl = window.location.href.includes('sso.stage.buzzfeed.io')
      ? LOCAL_API
      : API_URL;

    async function fetchItems() {
      // this is just for testing, turn back to just fetchPageNum later V
      const url = `${apiUrl}everything-else?feed=${feed}&page=${fetchPageNum}&size=${FETCH_PAGE_SIZE}`;
      try {
        const { items } = await (await fetch(url)).json();
        if (items.length < FETCH_PAGE_SIZE) {
          setFetchFinished(true);
        }
        if (items.length <= 0) {
          return;
        }
        const normalizedData = normalizeFeedData(items, MORE, 'web-hf');
        setItemQueue([...itemQueue, ...normalizedData.items]);
      } catch (e) {
        return;
      }
    }
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPageNum]);

  // preload items into the queue for the page
  useEffect(() => {
    if (canLoad && !fetchFinished && !isFinished) {
      setFetchPageNum(fetchPageNum + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canLoad]);

  return (
    <>
      {displayPages.map((page, index) => {
        if (page.type === 'ad') {
          const { key, slot } = page;
          return (
            <div key={key}>
              {!isMobile && (
                <AdSectionWide
                  key={`wide-${key}`}
                  config={slot}
                  isMobile={isMobile}
                  className="bottom-feed"
                />
              )}
              {isMobile && (
                <AdSection
                  key={`mweb-${key}`}
                  config={slot}
                  isMobile={isMobile}
                />
              )}
            </div>
          );
        } else {
          const { key, items } = page;
          // this is for CET to make `positionInSubunit` accurate w/ pagination
          const subunitPositionModifier = rank => {
            // don't count the page we're on totally;
            // rank counts it from the Feed/FeedItem component
            return (page.counter - 1) * items.length + rank;
          };
          return (
            <div className="xs-flex" key={key}>
              <div className="homepage-leftColumn">
                <Feed
                  items={items}
                  columns={1}
                  typeInfo={typeInfo}
                  hasRightText
                  positionInUnit={positionInUnit}
                  subunitName={SUBUNIT_NAME}
                  modifySubunitPosition={subunitPositionModifier}
                  lazyLoadThumbnails={true}
                />
              </div>
              {!isMobile && <EverythingElseSidebar index={index} />}
            </div>
          );
        }
      })}
      {!isFinished && (
        <Button
          className="xs-mt5 xs-block xs-col-12 sm-inline-block sm-width-auto"
          onClick={handlePageButton}
          disabled={isLoading}
          size="md"
          ref={setObservable}
        >
          Load More
          {isLoading && (
            <span className={`${styles.spinner} xs-inline-block`} />
          )}
        </Button>
      )}
      {isFinished && <div className="xs-mb5"></div>}
    </>
  );
};

export default EverythingElse;
