/* eslint-disable camelcase */
import React from 'react';
import { ClockIcon } from '@buzzfeed/react-components';
import LinkWithTracking from '../../LinkWithTracking';
import { CategoryLabel } from './_categoryLabel';
import {
  NEWS,
  SHOPPING,
  TASTY,
  QUIZ,
  TRENDING,
  SPLASH,
  VIDEO,
} from '../../../constants/feeds';
import styles from './feedItem.module.scss';
import {
  useMediaQuery,
  useViewImpression,
} from '../../../hooks';
// sub components
import { Thumbnail } from '../Thumbnail';

export const FeedItem = ({
  columns = 2,
  hasRightText = false,
  isScrollableMobile = false,
  item,
  hasRank = false,
  rank,
  subunitName,
  subunitType,
  hasSmallTitle = false,
  lazyLoadThumbnails = false,
  positionInSubunit,
}) => {

  const screenSize = useMediaQuery();
  const baseTrackingData = {
    item_type: 'card',
    item_name: item.id,
    target_content_type: 'buzz',
    target_content_id: item.id,
    data_source_name: item.dataSource.name,
    data_source_algorithm: item.dataSource.algorithm,
    data_source_algorithm_version: item.dataSource.version,
    position_in_subunit: positionInSubunit,
    ...(subunitName ? {subunit_name: subunitName} : {}),
    ...(subunitType ? {subunit_type: subunitType} : {})
  };
  const articleTracking = { ...baseTrackingData };
  const { setObservable } = useViewImpression({
    trackingData: articleTracking
  });

  return (
    <li
      ref={obvEl => setObservable(obvEl)}
      role="group"
      aria-label="item"
      className={`
        ${styles.feedItem}
        ${isScrollableMobile ? styles.scrollableMobile : ''}
        ${columns === 1 ? styles.oneCol : ''}
        ${columns === 2 ? styles.twoCol : ''}
        ${columns === 3 ? styles.threeCol : ''}
        ${columns === 4 ? styles.fourCol : ''}
        ${columns === 6 ? styles.sixCol : ''}
        ${
          hasRightText ? `${styles.titleRight} xs-flex-row` : 'xs-flex-column'
        }`}
    >
      <div className={styles.thumbnailWrapper}>
        <Thumbnail
          item={item}
          rank={hasRank ? rank : -1}
          lazyLoadThumbnail={lazyLoadThumbnails}
          textBelowImage={!hasRightText}
          tracking={articleTracking}
        />
      </div>
      <div className={styles.textWrapper}>
        {item.type !== TASTY &&
          item.type !== SHOPPING &&
          item.type !== NEWS &&
          item.type !== QUIZ && (
            <CategoryLabel
              rightAlignedText={!hasRightText}
              label={item.categoryInfo.label}
              createdAt={item.createdAt || null}
              badges={item.badges}
              hideTrending={subunitName === 'trending'}
            />
          )}
        <LinkWithTracking
          href={item.url}
          commonTrackingData={articleTracking}
        >
          <h2 className={hasSmallTitle ? styles.smallTitle : styles.title}>
            {item.name}
          </h2>
        </LinkWithTracking>
        {!screenSize.isMobile &&
          item.author?.name &&
          item.type !== TRENDING &&
          item.type !== SPLASH &&
          item.type !== VIDEO && (
            <div className="xs-text-6 text-gray xs-mt1">
              by {item.author.name}
            </div>
          )}
        {item.tasty.brandName && (
          <div className={styles.brand}>
            Presented by {item.tasty.brandName}
          </div>
        )}
        {item.tasty.isUnder30Min && (
          <div className={styles.cooktimeLabel}>
            <ClockIcon className="svg-5" />
            <p>Under 30 min</p>
          </div>
        )}
      </div>
    </li>
  );
};

export default FeedItem;
