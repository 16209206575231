export const YoutubePlayButton = ({ className }) => (
  <svg viewBox="0 0 72 50" className={className}>
    <path
      d="m69.941 7.824c1.53 5.588 1.471 17.235 1.471 17.235s0 11.588-1.471 17.176c-.823 3.059-3.235 5.471-6.294 6.294-5.588 1.471-27.941 1.471-27.941 1.471s-22.294 0-27.941-1.529c-3.059-.824-5.471-3.236-6.294-6.295-1.471-5.529-1.471-17.176-1.471-17.176s0-11.588 1.471-17.176c.823-3.059 3.294-5.53 6.294-6.353 5.588-1.471 27.941-1.471 27.941-1.471s22.353 0 27.941 1.529c3.059.824 5.471 3.236 6.294 6.295zm-22.765 17.176-18.588-10.706v21.412z"
      className="svg-black"
      opacity="0.7"
    ></path>
    <path d="m47.176 25-18.588-10.706v21.412z" className="svg-white"></path>
  </svg>
);
