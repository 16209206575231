import { ViralArrowIconAlt } from '@buzzfeed/react-components';
export const CategoryLabel = ({
  createdAt = null,
  label,
  badges = [],
  rightAlignedText = false,
  hideTrending = false,
}) => {
  const isViral = (!hideTrending && badges.includes('viral')) || false;
  return (
    <div
      className={`text-gray xs-text-6 xs-mb1 ${
        !rightAlignedText ? 'md-mt1' : ''
      }`}
    >
      <span className={`bold ${isViral ? 'text-red' : ''}`}>
        {isViral && <ViralArrowIconAlt className="svg-5 svg-red xs-mr05" />}
        <span>{isViral ? 'Trending' : label}</span>
      </span>
      {createdAt && (
        <>
          <span className="xs-mx05"> &bull; </span>
          <span> {createdAt}</span>
        </>
      )}
    </div>
  );
};
