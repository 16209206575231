export const YellowArrowsDesktop = () => (
  <svg viewBox="0 0 327 314" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M229.856 96.8102L229.855 289.251L37.4077 96.8097L229.856 96.8102Z"
      fill="#FFEE00"
    />
    <path
      d="M126.186 187.763L126.185 313.853L0.0996993 187.76L126.186 187.763Z"
      fill="#FFEE00"
    />
  </svg>
);

export const YellowArrowsMobile = () => (
  <svg
    width="117"
    height="117"
    viewBox="0 0 117 117"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M117 78.1059V0H38.8941L77.7882 38.8941H0L78.1139 117V39.2118L117 78.1059Z"
      fill="#FFEE00"
    />
  </svg>
);
