export const placeholderGIFs = {
  '1:1':
    'data:image/gif;base64,R0lGODlhAQABAIAAAP///////yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==',
  '4:3':
    'data:image/gif;base64,R0lGODlhBAADAIAAAP///////yH5BAEAAAEALAAAAAAEAAMAAAIDjI9WADs=',
  '6:4':
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAQAAAA3fa6RAAAADklEQVR42mNkAANGCAUAACMAA2w/AMgAAAAASUVORK5CYII=',
  '16:9':
    'data:image/gif;base64,R0lGODlhEAAJAIAAAP///////yH5BAEAAAEALAAAAAAQAAkAAAIKjI+py+0Po5yUFQA7',
};
