import LinkWithTracking from '../../LinkWithTracking';
import { TASTY, QUIZ, VIDEO } from '../../../constants/feeds';
import { placeholderGIFs } from '../../../constants/styling';
import { VideoThumbnail } from './VideoThumbnail';
import { QuizBadge } from '../../../icons/QuizBadge';
import styles from './thumbnail.module.scss';

export const Thumbnail = props => {
  const {
    item,
    rank,
    lazyLoadThumbnail = false,
    textBelowImage = false,
    tracking = {}
  } = props;
  return (
    <LinkWithTracking 
      href={item.url}
      commonTrackingData={tracking}
    >
      {item.type === VIDEO && (
        <VideoThumbnail {...props}>
          <ImageThumbnail
            type={item.type}
            thumbnail={item.thumbnail}
            alt={item.thumbnailAlt}
          />
        </VideoThumbnail>
      )}
      {item.type !== VIDEO && (
        <ImageThumbnail
          type={item.type}
          thumbnail={item.thumbnail}
          alt={item.thumbnailAlt}
          lazyLoadThumbnail={lazyLoadThumbnail}
          textBelowImage={textBelowImage}
        />
      )}
      {rank > -1 && (
        <>
          <div className="screen-reader-only"> Position in trending list: </div>
          <div className={`${styles.badge} ${styles.rank}`}> {rank} </div>
        </>
      )}
      {/* Doin't let quiz badge hide rank badge */}
      {rank < 0 && item.badges.includes('quiz') && item.type !== QUIZ && (
        <div className={styles.badge}>
          <QuizBadge />
        </div>
      )}
      {item.tasty.isCommunityRecipe && (
        <span className={styles.ugcLabel}>Community</span>
      )}
    </LinkWithTracking>
  );
};

export const ImageThumbnail = ({ type, thumbnail, alt, lazyLoadThumbnail, textBelowImage }) => {
  return (
    <>
      <img
        className={`${styles.thumbnailPlaceholder} ${textBelowImage ? ' xs-mb05' : ''}`}
        src={placeholderGIFs[type === TASTY ? '1:1' : '6:4']}
        alt=""
        aria-hidden={true}
      />
      <img className={styles.thumbnail} src={thumbnail} alt={alt} loading={lazyLoadThumbnail ? 'lazy' : ''} />
    </>
  );
};

export default Thumbnail;
