import React from 'react';
import { YoutubePlayButton } from '../../../icons/YoutubePlayButton';
import styles from './thumbnail.module.scss';

export const VideoThumbnail = ({ item, children }) => {
  const { videoDurationDisplay } = item;

  return (
    <div className={styles.videoThumbnailWrapper}>
      {children}
      <div className={styles.videoPlayBtn}>
        <YoutubePlayButton />
      </div>
      <div className={styles.videoCta}>
        <div className={styles.videoDurationTag}>{videoDurationDisplay}</div>
        {/*  for rotating time duration
        <div className={styles.videoWatchTag}>WATCH</div> */}
      </div>
    </div>
  );
};

export default VideoThumbnail;
