const defaultSponsorData = {
  heading: 'Want some holiday cheer?',
  name: 'EXmas',
  description: 'Click the toggle to explore a holiday-themed feed, brought to you by EXmas.',
  url: 'https://www.amazon.com/EXmas-Leighton-Meester/dp/B0CGRZXG54',
};

const sponsorData = {
  ...defaultSponsorData,
};

const flippedSponsorData = {
  ...defaultSponsorData,
  description: 'Hit the toggle button to head back the the BuzzFeed homepage.',
};

export { sponsorData, flippedSponsorData };
