import {
  YellowArrowsDesktop,
  YellowArrowsMobile,
} from '../../../icons/YellowArrows';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import styles from './feedHeading.module.scss';
import { TakeoverContext } from '../../../contexts';
import { useContext } from 'react';

export const FeedHeading = ({ heading, hasArrows = false, isSubheading }) => {
  const screenSize = useMediaQuery();
  // When a takeover is active, the arrows should not be displayed
  // https://buzzfeed.atlassian.net/browse/PAR-1129
  const takeover = useContext(TakeoverContext);
  hasArrows = hasArrows && !takeover;

  return (
    <>
      {heading && (
        <div
          className={`${styles.feedHeadingWrapper} ${
            hasArrows ? 'xs-mt5' : ''
          }`}
        >
          {isSubheading ? (
            <h3 className={styles.feedHeading}>{heading}</h3>
          ) : (
            <h2 className={styles.feedHeading}>{heading}</h2>
          )}
          {hasArrows && (
            <div className={styles.arrows}>
              {screenSize.isMobile ? (
                <YellowArrowsMobile />
              ) : (
                <YellowArrowsDesktop />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default FeedHeading;
