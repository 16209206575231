/* eslint-disable camelcase */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { cookies } from '@buzzfeed/bf-utils';
import { useIntersection, CrossIcon } from '@buzzfeed/react-components';

import { StickyContext } from '../../contexts';
import styles from './feedFlip.module.scss';
import { useTrackingContext, useViewImpression } from '../../hooks';
import classnames from 'classnames';

const FeedFlip = ({ sponsor, isChecked: defaultChecked }) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);
  const [isClosed, setIsClosed] = useState(false);
  const [height, setHeight] = useState(64);
  const feedFlipContainer = useRef(null);
  const stickyRegistry = useContext(StickyContext);
  const {
    trackContentAction,
    trackExternalLink,
    trackInternalLink,
  } = useTrackingContext();
  const trackingData = {
    position_in_unit: 0,
    item_type: 'button',
    item_name: 'flip_toggle',
    target_content_type: 'feed',
    // when toggle is turned ON, the target feed is the Homepage
    target_content_id: defaultChecked ? 1 : 'home-flipped',
  };
  const { setObservable: setImpressionObservable } = useViewImpression({ trackingData })
  // Observes this component's container.
  // When the user scrolls past the initial placement of the component,
  // the content will stick to the top of the page.
  // The container itself does not receive `position: fixed`,
  // so that when the user scrolls back up, we can place the content back.
  const { isIntersecting, setObservable } = useIntersection({
    defaultValue: true,
  });

  useEffect(() => {
    if (feedFlipContainer.current) {
      setObservable(feedFlipContainer.current);
      setHeight(feedFlipContainer.current.getBoundingClientRect().height);
    }
    stickyRegistry.add(feedFlipContainer.current);

    return () => {
      setObservable(null);
      stickyRegistry.remove(feedFlipContainer.current);
    };
  }, [feedFlipContainer, setObservable, stickyRegistry]);

  useEffect(() => {
    if (defaultChecked !== isChecked) {
      cookies.set({
        name: 'x_bf_homepage_alias',
        value: 'home-flipped',
        // When the toggle is being switched OFF, the cookie should be deleted
        days: isChecked ? 1 : -1,
        domain: window.location.hostname,
      });
      window.location.reload();
    }
  }, [defaultChecked, isChecked]);

  useEffect(() => {
    if (isIntersecting) {
      setIsClosed(false);
      stickyRegistry.add(feedFlipContainer.current);
    }
  }, [isIntersecting, stickyRegistry]);

  const handleToggle = ({ target: { checked } }) => {
    setIsChecked(checked);
    trackInternalLink(trackingData);
  };

  const handleSponsorLink = () => {
    trackExternalLink({
      position_in_unit: 1,
      item_type: 'text',
      item_name: 'sponsor_link',
      target_content_url: sponsor?.url,
    });
  };

  const handleCloseButton = () => {
    setIsClosed(true);
    stickyRegistry.remove(feedFlipContainer.current);
    trackContentAction({
      position_in_unit: 2,
      item_type: 'button',
      item_name: 'close',
      action_type: 'hide',
      action_value: 'hide',
    });
  };

  return (
    <div
      className={classnames(styles.feedFlip, {[styles.postFlipped]: defaultChecked})}
      ref={feedFlipContainer}
      style={{ paddingTop: !isIntersecting ? height : 0 }}
    >
      <div
        className={classnames({
          [styles.fixed]: !isIntersecting,
          'xs-hide': isClosed,
          'xs-block': !isClosed,
        })}
      >
        <div
          className={`${
            !isIntersecting ? styles.fixedWrapper : styles.contentWrapper
          }`}
        >
          {!isIntersecting && (
            <CrossIcon
              className={styles.closeBtn}
              onClick={handleCloseButton}
              fill={'white'}
            />
          )}
          <div className={styles.toggleWrapper}>
            <input
              ref={toggle => setImpressionObservable(toggle)}
              type="checkbox"
              className={styles.toggle}
              checked={isChecked}
              onChange={handleToggle}
            />
          </div>
          <div className={styles.infoWrapper}>
            <div className={`${styles.titleWrapper} xs-mb1 md-mb0`}>
              <h2 className={'xs-block md-inline-block md-mr05 xs-mb05 md-mb0'}>
                {sponsor?.heading}
              </h2>
              <div
                className={`md-inline ${styles.description} ${
                  !isIntersecting ? 'xs-hide' : ''
                }`}
                dangerouslySetInnerHTML={{ __html: sponsor?.description }}
              />
            </div>
            <div
              className={`xs-block md-inline-block xs-text-5 ${styles.promotedByText}`}
            >
              Presented by{' '}
              <a
                href={sponsor?.url}
                target="_blank"
                className={styles.sponsorLink}
                rel="nofollow noopener noreferrer"
                onClick={handleSponsorLink}
              >
                {sponsor?.name}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedFlip;
