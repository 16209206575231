export const QuizBadge = ({ className }) => (
  <svg
    role="img"
    aria-label="Quiz badge"
    viewBox="0 0 40 40"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 39C30.4934 39 39 30.4934 39 20C39 9.50659 30.4934 1 20 1C9.50659 1 1 9.50659 1 20C1 30.4934 9.50659 39 20 39Z"
      fill="#FFEE00"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M14.0524 28.3659C14.5656 28.0572 14.9933 27.6635 15.3304 27.2276L16.1668 27.5481L16.7941 25.8545L16.0882 25.5795C16.2751 24.6581 16.1262 23.6517 15.6003 22.706C14.4433 20.62 12.1319 20.0614 10.117 21.2729C8.10214 22.4838 7.40073 24.852 8.55834 26.938C9.71596 29.026 12.0369 29.5774 14.0518 28.3665L14.0524 28.3659ZM13.1856 26.8028C12.099 27.4558 10.9033 27.0099 10.2875 25.8993C9.66653 24.7782 9.8978 23.4875 10.9845 22.8346C12.0711 22.1817 13.2515 22.6236 13.8737 23.7447C14.0727 24.1048 14.176 24.4807 14.207 24.8461L13.1514 24.4385L12.5241 26.1321L13.5481 26.5305C13.4365 26.6386 13.3136 26.7263 13.1856 26.8035V26.8028ZM21.3624 23.9736C23.4755 22.7027 23.7955 20.9069 22.8495 19.2001L20.5913 15.129L18.8729 16.1617L21.1033 20.1814C21.5531 20.9926 21.4042 21.8645 20.495 22.4106C19.5775 22.9626 18.7779 22.6612 18.328 21.85L16.0977 17.8296L14.3692 18.8684L16.6331 22.9494C17.5683 24.6357 19.2487 25.2431 21.3624 23.9729V23.9736ZM27.0922 20.3648L23.2892 13.5086L21.5911 14.5295L25.3928 21.3863L27.0909 20.3641L27.0922 20.3648ZM33 16.8153L32.1446 15.2734L29.2604 17.0066L29.9029 11.3981L29.1267 10L24.0793 13.0337L24.9347 14.575L27.7391 12.8899L27.1011 18.4825L27.8817 19.8906L32.9981 16.816L33 16.8153Z"
      fill="#222222"
    />
  </svg>
);
