import { useMediaQuery } from '../../../hooks';
import AdUnit from '../AdUnit';

const AdSectionAwareness = ({ isMPHomepage, position = 'top' }) => {
  const { isMobile } = useMediaQuery();

  if (!isMPHomepage) {
    return (
      <AdUnit type="awareness" />
    );
  } else if (isMPHomepage && isMobile && position === 'top') {
    return (
      <AdUnit type="awareness" className="md-hide lg-hide" />
    );
  } else if (isMPHomepage && !isMobile && position === 'body') {
    return (
      <AdUnit className="xs-hide sm-block xs-my4" type="awareness" />
    );
  } else if (isMPHomepage && isMobile && position === 'body') {
    return (
      <AdUnit className="md-hide lg-hide xs-my4 xs-mx2" type="story1" />
    );
  } else {
    return null;
  }
};

export default AdSectionAwareness;
