import adsConfig from '../../../constants/ads-config';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { buildPosBound, getWireframeOptions, addBillboards } from '../../../utils/ads/ad-unit';
import AdUnit from '../../Ads/AdUnit';

function buildPos(posnum) {
  return buildPosBound('story', posnum, 40);
}

export const AdSectionWide = ({
  config = null,
  posnum = null,
  className = '',
  isMobile = false
}) => {
  const screenSize = useMediaQuery();

  if(!screenSize) {
    return null;
  }

  const pos = buildPos(posnum);
  let cfg;
  if (!config) {
    cfg = { ...adsConfig[pos] };
  } else {
    cfg = { ...config };
  }

  let extraClasses = `Ad--section-wide xs-hide sm-block ${className}`.trim();

  function handleLayoutChange(bfpName) {
    if (bfpName === 'bfp_spotlight_ad') {
      return 'wide';
    }
    return null;
  }

  if(screenSize.isLargeScreen) {
    cfg.size = addBillboards(cfg);
  }

  return (
    !isMobile &&
    <AdUnit
      config={({...cfg, ...getWireframeOptions(cfg)})}
      className={extraClasses}
      getBFPLayout={handleLayoutChange}
    />
  );
};

export const AdSection = ({
  config = null,
  posnum,
  isMobile = true,
  className = '',
}) => {
  const pos = config ? null : buildPos(posnum);
  const extraClasses = `md-hide lg-hide xs-my4 ${className}`.trim();
  return (
    isMobile && <AdUnit config={config} type={pos} className={extraClasses} />
  );
};
