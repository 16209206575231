import React, { useContext } from 'react';
import ABeagleProvider from '../ABeagleProvider';
import Ads from '../Ads/Ads';
import AdUnit from '../Ads/AdUnit';
import AdSectionAwareness from '../Ads/units/AdSectionAwareness';
import ConsentManagement from '../ConsentManagement';
import GoogleTagManager from '../GoogleTagManager';
import TimeSpentTrackingConsumer from '../TimeSpentTrackingConsumer';
import StickyManager from '../StickyManager';
import {
  ConsentProvider,
  PageContext,
} from '../../contexts';
import {
  usePageviewTracking,
  useComscoreTracking,
  usePerformanceTracking,
  useNavTracking,
} from '../../hooks';
import { useConsent } from '@buzzfeed/react-components';
import { gtm_enabled } from '../../constants';

export function HomepageWrapper({ children, pageName, isMPHomepage = false }) {
  const { tracking, edition, pageEdition, pagePath } = useContext(PageContext);
  const { consentValue, isConsentKnown, isConsentReady } = useConsent('tracking');

  usePageviewTracking({});
  useComscoreTracking();
  useNavTracking();
  usePerformanceTracking();

  return (
    <ConsentProvider value={{ tracking: { consentValue, isConsentKnown, isConsentReady } }}>
      <ABeagleProvider edition={edition} tracking={tracking} isMPHomepage={isMPHomepage} pageName={pageName}>
        <TimeSpentTrackingConsumer />
        <ConsentManagement />
        {gtm_enabled && <GoogleTagManager />}
        <StickyManager>
          <Ads edition={pageEdition} pagePath={pagePath} pageName={pageName} isMPHomepage={isMPHomepage}>
            <AdUnit type="toolbar" />
            <AdSectionAwareness isMPHomepage={isMPHomepage} position="top" />
            {children}
          </Ads>
        </StickyManager>
      </ABeagleProvider>
    </ConsentProvider>
  );
}

export default HomepageWrapper;
