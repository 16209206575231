/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import SeeMoreLink from '../SeeMoreLink/SeeMoreLink.jsx';
import { AdInFeed } from '../Ads/units/AdInFeed';
import FeedItem from './FeedItem';
import styles from './feed.module.scss';
import FeedHeading from './FeedHeading';


export const Feed = ({ hasAds = false, adManager, items, ...props }) => {
  const [displayedItems, setDisplayedItems] = useState([]);

  useEffect(() => {
    const nextDisplayedItems = [...items];
    if (adManager) {
      let idx = 0;
      do {
        const ad = adManager.getAdForPlacement(idx);
        const item = nextDisplayedItems[idx];
        const isAdItem = item && item.type === 'ad';

        if (adManager.isDone(ad)) {
          // there will be no more ads
          break;
        }
        if (ad) {
          ad.type = 'ad';

          // insert an ad at index, or replace the current item if it's already an ad (inserted earlier)
          nextDisplayedItems.splice(idx, isAdItem ? 1 : 0, ad);
          idx++;
        }

        idx++;
      } while (idx < nextDisplayedItems.length);

      // reset the manager so it starts generating ads from the beginning when the effect runs next time
      adManager.reset();
    }
    setDisplayedItems(nextDisplayedItems);
  }, [items, adManager]);

  if (!hasAds) {
    return <FeedPresenter {...props} items={items} hasAds={hasAds} />;
  }
  if (!displayedItems.length) {
    return null;
  }
  return <FeedPresenter {...props} items={displayedItems} hasAds={hasAds} />;
};

export const FeedPresenter = ({
  items,
  heading,
  columns = 2,
  hasRightText = false,
  isScrollableMobile = false,
  typeInfo,
  hasArrows = false,
  hasRank = false,
  modifySubunitPosition = null,
  hasSeeMoreLink,
  hasAds = false,
  hasSmallTitle = false,
  lazyLoadThumbnails = false,
  hideTrendingLabels = false,
  subunitName,
  subunitType,
}) => {
  let postRank = 0;
  let seeMoreTrackingData = {};
  const getNextRank = () => (postRank += 1);

  if (hasSeeMoreLink) {
    seeMoreTrackingData = {
      item_type: 'text',
      item_name: `see_more_${typeInfo.seeMoreTrackingName}`,
      position_in_subunit: items.length,
      target_content_type: 'buzz',
      target_content_id: typeInfo.seeMoreTrackingName,
    };
  }

  return (
    <section
      className={`
      ${isScrollableMobile ? styles.scrollableMobile : ''}
      ${columns === 1 ? styles.oneCol : ''}
      `}
    >
      {heading && <FeedHeading heading={heading} hasArrows={hasArrows} />}
      {items && (
        <ul className={styles.feedItems}>
          {items.map(item => {
            if (hasAds && item.type === 'ad') {
              return (
                <li
                  className={styles.feedItemAd}
                  role="group"
                  aria-label="Advertisement"
                  key={item.key}
                >
                  <AdInFeed config={item.slot} />
                </li>
              );
            }
            getNextRank();
            return (
              <FeedItem
                isScrollableMobile={isScrollableMobile}
                hasRightText={hasRightText}
                columns={columns}
                key={item.id}
                item={item}
                hasRank={hasRank}
                rank={postRank}
                positionInSubunit={
                  modifySubunitPosition
                    ? modifySubunitPosition(postRank - 1)
                    : postRank - 1
                }
                hasSmallTitle={hasSmallTitle}
                hideTrending={hideTrendingLabels}
                lazyLoadThumbnails={lazyLoadThumbnails}
                subunitName={subunitName}
                subunitType={subunitType}
              />
            );
          })}
        </ul>
      )}
      {hasSeeMoreLink && (
        <SeeMoreLink
          href={typeInfo.url}
          commonTrackingData={seeMoreTrackingData}
          className="xs-flex xs-text-4 md-text-3 bold xs-flex-align-center link-blue xs-mt2 md-mt3 xs-mb1"
        >
          See More {typeInfo.title}
        </SeeMoreLink>
      )}
    </section>
  );
};

export default Feed;
